<template>
  <Steps :step="2" />
  <div class="mt-5">
    <Form :actions="false" :service="service" @submit.prevent="onSubmit">
      <h1 class="fs-3">{{ $t('Payees details') }}</h1>
      <div class="p-fluid mt-4 mb-3">
        <div class="p-field">
          <label for="provider-value">{{ $t('Label') }}</label>
          <AutoComplete
            id="provider-value"
            v-model="service.providerValue"
            :suggestions="filteredDadataOrg"
            placeholder="ИП Иванов Иван Иванович"
            @input="getDadataOrg(service.providerValue)"
            @complete="searchDadataOrg($event)"
            field="value"
            modelValue="providerName"
            :class="service.errors && service.errors.providerValue ? 'p-invalid is-invalid' : ''"
          >
            <template #item="slotProps">
              <div>{{ slotProps.item.value }}</div>
              <div class="text-muted fs-7">
                {{ slotProps.item.data.address.value }}
              </div>
            </template>
          </AutoComplete>
          <div v-if="service.errors && service.errors.providerValue" class="invalid-feedback">
            {{ service.errors ? service.errors.providerValue : null }}
          </div>
        </div>
      </div>
      <FormField :service="service" field="providerInn" :label="$t('INN')" />
      <FormField :service="service" field="providerKpp" :label="$t('KPP')" />
      <div class="mt-5">
        <h1 class="fs-3">{{ $t('Contact details') }}</h1>
        <FormField :service="service" field="providerAddress" :label="$t('Address')" />
        <FormField
          :service="service"
          field="providerPhone"
          :label="$t('Phone')"
          type="mask"
          mask="+7 (999) 999-99-99"
          placeholder="+7 (999) 123-45-67"
        />
      </div>
      <div class="mt-5">
        <h1 class="fs-3">{{ $t('Bank details') }}</h1>
        <FormField :service="service" field="providerBank" :label="$t('Bank')" />
        <div class="p-fluid mt-4 mb-3">
          <label :for="service.providerBankValue">{{ $t('BIC') }}</label>
          <div class="p-field">
            <AutoComplete
              v-model="service.providerBankValue"
              :suggestions="filteredDadataBank"
              @input="getDadataBank(service.providerBankValue)"
              @complete="searchDadataBank($event)"
              field="data.bic"
              modelValue="data.bic"
              :class="service.errors && service.errors.providerBic ? 'p-invalid is-invalid' : ''"
            >
              <template #item="slotProps">
                <div>{{ slotProps.item.value }}</div>
                <div class="text-muted fs-7">
                  {{ slotProps.item.data.bic }}
                </div>
              </template>
            </AutoComplete>
            <div v-if="service.errors && service.errors.providerBic" class="invalid-feedback">
              {{ service.errors ? service.errors.providerBic : null }}
            </div>
          </div>
        </div>
        <FormField :service="service" field="providerCorrAccount" :label="$t('C/a')" />
        <FormField
          :service="service"
          field="providerBeneficiaryAccount"
          :label="$t('Beneficiary account')"
          type="number"
          :maxlength="20"
        />
      </div>
      <div class="mt-5">
        <h1 class="fs-3">{{ $t('Signature on the account') }}</h1>
        <FormField :service="service" field="providerFullName" :label="$t('Full name')" />
        <FormField :service="service" field="providerPositionMain" :label="$t('Position')" />
      </div>
      <FormField
        :service="service"
        field="isSignAccountChief"
        :label="$t('Add the signature of the chief accountant')"
        type="checkbox"
      />
      <div v-if="service.isSignAccountChief" class="mt-5">
        <FormField :service="service" field="providerFullNameAccountChief" :label="$t('Full name account chief')" />
        <FormField :service="service" field="providerPositionAccountChief" :label="$t('Position')" />
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <Button :label="$t('Prev')" @click="prevPage" class="me-2 custom-p-button-outlined p-button-lg" icon="pi pi-angle-left" />
        <Button type="submit" :label="$t('Save and Continue')" class="p-button-lg button-main" icon="pi pi-angle-right" icon-pos="right" />
      </div>
    </Form>
  </div>
</template>

<script>
import axios from 'axios';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Form from '../../../../components/ui/Form.vue';
import FormField from '../../../../components/ui/FormField.vue';
import MultiField from '../../../../components/ui/MultiField.vue';
import Steps from '../../components/Steps.vue';

export default {
  components: {
    AutoComplete,
    Button,
    Form,
    FormField,
    MultiField,
    Steps
  },
  data() {
    return {
      service: this.$store.state.service,
      dadataBank: null,
      filteredDadataBank: null,
      dadataOrg: null,
      filteredDadataOrg: null,
    };
  },
  watch: {
    'service.providerBankValue': {
      handler(val) {
        if (val.data) {
          console.log(val);
          let items = val.data;
          this.service.providerBank = val.value;
          this.service.providerBic = items.bic;
          this.service.providerCorrAccount = items.correspondent_account;
        }
      },
      deep: true,
    },
  },
  methods: {
    prevPage() {
      this.service.step = 1;
      this.$emit('step', 'Amount');
    },
    onSubmit() {
      this.service.validate();
      if (Object.keys(this.service.errors).length == 0) {
        this.service.step = 3;
        this.$emit('step', 'payerDetails');
      }
    },
    // Поиск по банку
    searchDadataBank() {
      setTimeout(() => {
        this.filteredDadataBank = this.dadataBank.suggestions.filter((data) => {
          return data;
        });
      }, 250);
    },
    async getDadataBank(data) {
      let url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank';
      var token = '4c5ff6f2a9bd28e43d1dc89e557b94ac23582551';
      var query = data;

      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        data: {
          query: query,
        },
      };
      await axios(options)
        .then((response) => response.data)
        .then((result) => {
          this.dadataBank = result;
        })
        .catch((error) => console.log('error', error));
    },
    // Поиск по организациям
    searchDadataOrg() {
      setTimeout(() => {
        this.filteredDadataOrg = this.dadataOrg.suggestions.filter((data) => {
          return data;
        });
      }, 250);
    },
    async getDadataOrg(data) {
      let url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
      var token = '4c5ff6f2a9bd28e43d1dc89e557b94ac23582551';
      var query = data;

      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        data: {
          query: query,
        },
      };
      await axios(options)
        .then((response) => response.data)
        .then((result) => {
          this.dadataOrg = result;
        })
        .catch((error) => console.log('error', error));
    },
  },
};
</script>
