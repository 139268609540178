<template>
    <div class="d-flex justify-content-around">
      <template v-for="(tab, i) in tabs" :key="i">
          <div :class="[step == i + 1 ? 'active ' : ''] + ['text-center'] + [step < i + 1 ? '' : '']">
            <span><i :class="'text-main fs-2 far' + [step == i + 1 ? ' fa-dot-circle' : (step < i + 2 ? ' fa-circle' : ' fa-check-circle')]"></i></span>
            <div class="mt-2 fs-7 text-main" style="max-width: 73px">{{ tab.label }}</div>
          </div>
      </template>
    </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components: {
    Button
  },
  props: {
    step: Number,
  },
  data() {
    return {
      tabs: [
        {
          component: "Amount",
          label: this.$t("Invoice amount"),
        },
        {
          component: "RecipientDetails",
          label: this.$t("Recipient details"),
        },
        {
          component: "PayerDetails",
          label: this.$t("Payer details"),
        },
        {
          component: "Confirmation",
          label: this.$t("Signatures and seal"),
        },
      ],
    }
  },
};
</script>
