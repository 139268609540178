<template>
  <SpeedDial
    v-if="model && (length > 1 ? true : isVisible(model[0].visible))"
    :ref="(el) => (container = el)"
    :model="model"
    :direction="type === 'left' ? 'right' : 'left'"
    :showIcon="showIcon"
    @click="length > 1 ? () => {} : onLoading(onClick, service, model[0])"
    :class="'custom-speeddial-' + (type || 'fixed')"
    :buttonClass="buttonClass"
    :rotateAnimation="length > 1"
    :disabled="model[0].disabled"
  >
    <template #button>
      <Button
        type="button"
        :class="container.buttonClassName"
        :icon="container.iconClassName"
        @click="container.onClick($event)"
        :disabled="container.disabled"
        :label="label"
        v-tooltip.left="tooltip"
      />
    </template>
    <template #item="{ item }">
      <Link
        v-if="length > 1 && isVisible(item.visible)"
        :to="item.to"
        :data="service"
        v-tooltip.top="item.label"
        role="menuitem"
        class="p-speeddial-action"
        @click="
          item.onClick ? onLoading(item.onClick, service, item) : () => {}
        "
      >
        <span :class="'p-speeddial-action-icon ' + item.icon"></span>
      </Link>
    </template>
  </SpeedDial>
</template>

<script>
import SpeedDial from 'primevue/speeddial'
import Button from 'primevue/button'
import Link from './Link.vue'
import Str from '../../utils/Str'

export default {
  components: { SpeedDial, Button, Link },
  props: {
    actions: Array | Function,
    service: Object,
    type: String,
    isSuccessToast: Boolean,
  },
  emits: ['load'],
  data() {
    return {
      Str: Str,
      onClick: (service) => {},
      buttonClass: 'p-button-secondary',
      container: {},
      model: [],
      confirmMessage: this.$t('Are you sure you want to proceed?'), // выносим в переменные, т.к. почему-то при втором обращении к компоненту $t не срабатывает если его использовать напрямую
      confirmHeader: this.$t('Confirmation'),
    }
  },
  computed: {
    length() {
      let actions = this.model

      if (actions.length === 1) {
        return actions.length
      }

      let length = 0
      for (let action of actions) {
        let visible = this.isVisible(action.visible)
        if (visible) {
          length++
        }
      }

      return length
    },
    showIcon() {
      if (this.length === 1) {
        return this.model[0].icon
      }

      return 'pi pi-ellipsis-h'
    },
    tooltip() {
      if (this.length === 1 && this.model[0].isTooltip) {
        return this.model[0].label
      }
    },
    label() {
      if (this.length === 1 && this.model[0].isLabel) {
        return this.model[0].label
      }
    },
  },
  watch: {
    actions: 'startSet',
  },
  created() {
    this.startSet()
  },
  methods: {
    startSet() {
      if (typeof this.actions === 'function') {
        this.model = this.actions(this.service)
      } else {
        this.model = this.actions
      }

      if (!this.model) {
        return
      }

      for (let i in this.model) {
        let item = this.model[i]

        if (
          item === 'show' ||
          item === 'create' ||
          item === 'update' ||
          item === 'save' ||
          item === 'delete'
        ) {
          item = this.model[i] = { action: item }
        }

        if (item.action === 'show') {
          this.model[i].label = item.label || this.$t('Show')
          this.model[i].icon = item.icon || 'pi pi-eye'
          this.model[i].to = item.to
            ? item.to
            : (service) => Str.topPath() + '/' + service.id
        } else if (item.action === 'create') {
          this.model[i].label = item.label || this.$t('Create')
          this.model[i].icon = item.icon || 'pi pi-plus'
          this.model[i].to = item.to || (() => Str.topPath() + '/create')
        } else if (item.action === 'update') {
          this.model[i].label = item.label || this.$t('Edit')
          this.model[i].icon = item.icon || 'pi pi-pencil'
          this.model[i].to =
            item.to || ((service) => Str.topPath() + '/update/' + service.id)
        } else if (item.action === 'save') {
          this.model[i].label = item.label || this.$t('Save')
          this.model[i].icon = item.icon || 'pi pi-check'
          this.model[i].onClick = item.onClick || this.onSave
        } else if (item.action === 'delete') {
          this.model[i].label = item.label || this.$t('Delete')
          this.model[i].icon = item.icon || 'pi pi-trash'
          this.model[i].onClick = item.onClick || this.onDelete
        }
      }

      if (this.length === 1) {
        let item = this.model[0]
        if (item.action === 'create' || item.action === 'save') {
          this.buttonClass = 'p-button-success'
        } else if (item.action === 'delete') {
          this.buttonClass = 'p-button-danger'
        }

        if (item.onClick) {
          this.onClick = item.onClick
        } else if (item.to) {
          this.onClick = (service) => {
            this.$router.push(
              typeof item.to === 'function' ? item.to(service) : item.to
            )
          }
        }
      }
    },
    async onSave(service, item) {
      await service.save()

      if (Object.keys(service.errors).length === 0) {
        if (this.isSuccessToast) {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Успешно'),
            detail: 'Сохранено',
            group: 'bl',
            life: 10000,
          })
        }

        this.$router.push(
          item.to
            ? typeof item.to === 'function'
              ? item.to(service)
              : item.to
            : Str.topPath()
        )
      } else {
        let errorMsg = ''
        for (let i in service.errors) {
          errorMsg = service.errors[i]
          break
        }
        this.$toast.add({
          severity: 'error',
          summary: this.$t('Error'),
          detail: errorMsg,
          group: 'bl',
          life: 10000,
        })
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async onDelete(service) {
      let skip = false

      await this.$confirm.require({
        message: this.confirmMessage,
        header: this.confirmHeader,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          if (this.service._selectedItems) {
            let ids = []
            for (let item of this.service._selectedItems) {
              ids.push(item.id)
            }
            this.service.deleteMass(ids)
          } else {
            await service.delete()
          }

          skip = true
        },
        reject: () => {
          skip = true
        },
      })

      while (!skip) {
        // это нужно, т.к. confirm от primeView не работает с асинхронностью
        await this.sleep(500)
      }
    },
    async onLoading(func, service, item) {
      // При загрузке дизейблим кнопку и меняем иконку до конца выполнения функции
      let icon = item.icon
      item.icon = 'pi pi-spin pi-spinner'
      item.disabled = true
      await func(service, item)
      item.icon = icon
      item.disabled = false

      this.$emit('load')
    },
    isVisible(modelVisible) {
      let visible =
        typeof modelVisible === 'function'
          ? modelVisible(this.service)
          : modelVisible

      if (visible || visible === undefined) {
        return true
      }

      return false
    },
  },
}
</script>
