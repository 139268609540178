<template>
  <a
    v-if="to && to.href"
    :href="typeof to.href === 'function' ? to.href(data) : to.href"
    :target="to.target"
  >
    <slot></slot>
  </a>
  <router-link
    v-else
    :to="to ? (typeof to === 'function' ? to(data) : to) : '#'"
    :target="to ? to.target : null"
  >
    <slot></slot>
  </router-link>
</template>
<script>
export default {
  props: {
    to: String | Object | Function,
    data: Object,
  },
}
</script>