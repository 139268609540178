<template>
  <div :class="'p-fluid mb-' + (margin || 3)">
    <div v-if="type === 'select'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <Dropdown
        :inputId="fieldStr"
        v-model="serviceF"
        :filter="filter"
        :filterMatchMode="filterMatchMode"
        :filterFields="filterFields"
        @filter="onFilter($event)"
        :options="options"
        :placeholder="placeholder"
        :showClear="showClear"
        :optionValue="optionValue || 'id'"
        :optionLabel="optionLabel || 'name'"
        @change="
          onInput($event, fieldStr);
          $emit('change', $event);
        "
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :editable="editable"
        :panelClass="panelClass"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'multiselect'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <MultiSelect
        :inputId="fieldStr"
        v-model="serviceF"
        :options="options"
        :optionValue="optionValue || 'id'"
        :optionLabel="optionLabel || 'name'"
        :optionGroupLabel="optionGroupLabel"
        :optionGroupChildren="optionGroupChildren"
        @change="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'checkbox'" class="p-field-checkbox">
      <Checkbox
        :id="fieldStr"
        v-model="serviceF"
        :binary="true"
        @input="onInput($event, fieldStr)"
        trueValue="1"
        :falseValue="falseValue || null"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <label class="ms-1" :for="fieldStr">{{ label }}</label>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
    </div>

    <div v-else-if="type === 'radio'" class="p-field">
      <label>{{ label }}</label
      ><br />
      <span v-for="(option, i) of options" class="p-field-radiobutton" :key="i">
        <RadioButton
          :id="fieldStr + '-' + i"
          v-model="serviceF"
          :name="fieldStr"
          :value="option[optionValue || 'id']"
          @change="onInput($event, fieldStr)"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
          :disabled="disabled"
        />
        <label class="ms-1 me-3" :for="fieldStr + '-' + i">
          {{ option[optionLabel || 'name'] }}
        </label>
        <div v-if="i === options.length - 1 && service.errors[fieldStr]" class="invalid-feedback">
          {{ service.errors ? service.errors[fieldStr] : null }}
        </div>
      </span>
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
    </div>

    <div v-else-if="type === 'textarea'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <Textarea
        :id="fieldStr"
        v-model="serviceF"
        :autoResize="true"
        @input="onInput($event, fieldStr)"
        :class="(service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '') + (!label ? 'mt-4' : '')"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'password'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <Password
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'mask'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <InputMask
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :mask="mask"
        :slotChar="slotChar"
        :class="(service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '') + (!label ? 'mt-4' : '')"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'number'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <input
        type="number"
        :id="fieldStr"
        v-model="serviceF"
        :class="
          'p-inputtext p-component p-inputnumber-input p-inputnumber-input ' +
          (service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '')
        "
        min="0"
        @input="onInput($event, fieldStr)"
        :disabled="disabled"
        :step="stepNumber"
        :maxlength="maxlength"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'calendar'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <Calendar
        :id="fieldStr"
        v-model="serviceF"
        :showIcon="true"
        :monthNavigator="true"
        :yearNavigator="true"
        yearRange="1920:2030"
        @update:modelValue="onInput($event, fieldStr, 'date')"
        :showButtonBar="true"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :selectionMode="selectionMode ?? 'single'"
        autocomplete="off"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'autocomplete'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <AutoComplete
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :suggestions="suggestions"
        @complete="complete"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :field="fields"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'color'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <ColorPicker
        v-model="serviceF"
        :inline="inline"
        :class="[service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '', 'd-block']"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'chips'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <Chips
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'file'" class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <div class="p-fileupload p-fileupload-advanced p-component">
        <div class="p-fileupload-buttonbar">
          <FileUpload
            :id="fieldStr"
            mode="basic"
            v-model="serviceF"
            :name="multiple ? fieldStr + '[]' : fieldStr"
            :url="url ?? linkProfile + '/upload/person'"
            @upload="onUpload"
            @before-send="onUploadBeforeSend"
            @before-upload="onUploadBefore"
            :auto="true"
            chooseLabel="Загрузить"
            :multiple="multiple"
            :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
            accept="image/*"
            :maxFileSize="20000000"
            :showUploadButton="false"
            :showCancelButton="false"
          ></FileUpload>
        </div>
        <div v-if="multiple" class="p-fileupload-content">
          <div class="p-fileupload-files" v-for="(img, i) in images" :key="i">
            <div class="p-fileupload-row">
              <div>
                <img :src="img.url" style="width: 100px" />
              </div>
              <div class="p-fileupload-filename">
                {{ img.name }}
              </div>
              <div>
                <Button
                  @click="onDeleteFile(i, service)"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else class="p-field">
      <label :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
      <InputText
        :id="fieldStr"
        :type="type || 'text'"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :class="(service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '') + (!label ? 'mt-4' : '')"
        :disabled="disabled"
        autofocus
        v-maska="maska"
        :placeholder="placeholder"
      />
      <div>
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Password from 'primevue/password';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import Chips from 'primevue/chips';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Str from '../../utils/Str';
import Button from 'primevue/button';
import ColorPicker from 'primevue/colorpicker';

export default {
  components: {
    InputText,
    Dropdown,
    MultiSelect,
    Checkbox,
    RadioButton,
    Textarea,
    Password,
    InputMask,
    InputNumber,
    Calendar,
    AutoComplete,
    Chips,
    FileUpload,
    Button,
    ColorPicker,
  },
  props: {
    service: Object,
    type: String,
    field: String,
    label: String,
    options: Array,
    optionValue: String,
    optionLabel: String,
    mask: String,
    maxlength: Number,
    slotChar: String,
    suggestions: Array,
    complete: Function,
    disabled: Boolean,
    inline: Boolean,
    filter: Boolean,
    filterMatchMode: String,
    filterFields: Array,
    showClear: Boolean,
    falseValue: String,
    editable: Boolean,
    panelClass: String,
    fields: String,
    multiple: Boolean,
    margin: String | Number,
    mode: String,
    optionGroupLabel: String,
    optionGroupChildren: String,
    stepNumber: Number,
    url: String,
    maska: Object,
    helpText: String,
    placeholder: String,
    selectionMode: String,
    tooltip: String,
  },
  data() {
    return {
      Str: Str,
      linkProfile: process.env.VUE_APP_API_URL,
      files: null,
      images: [],
      id: null,
    };
  },
  mounted() {
    this.id = Math.random().toString(36).substr(2, 9) + new Date().getTime();
  },
  methods: {
    onFilter(event) {
      this.service.filterValue = event.value;

      this.$emit('filter', event);
    },
    onInput(event, fieldStr, format) {
      if (format && event && !this.selectionMode) {
        this.serviceF = this.Str[format](this.serviceF);
      }

      if (this.service.errors) {
        delete this.service.errors[fieldStr];
      }

      this.$emit('input', event);
    },
    async onUploadBeforeSend(data) {
      data.xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('jwt-token'));

      let files = [];
      data.formData.forEach((file) => {
        files.push(file);
      });

      data.formData.append('tempObjectId', this.id);

      if (this.service.basePath === 'user') {
        data.formData.append('userId', this.service.id);
      }

      if (files.length > 1) {
        let formData = new FormData();

        for (let i in files) {
          let formData = new FormData();

          formData.append('tempObjectId', this.id);
          formData.append(this.field, files[i], files[i].name);

          console.log(i);
          console.log(files.length);
          if (i < files.length - 1) {
            console.log('xhr in');
            console.log(data.xhr);
            await data.xhr.send(formData);
            // console.log(data.xhr);
            // data.xhr.open('POST', this.linkProfile + '/upload/person', true);
          }
        }

        data.formData = formData;
      }
    },
    onUploadBefore(data) {
      // data.formData.append('file', this.files)
      console.log(data);
    },
    async onUpload(data) {
      console.log(data);
      console.log('1111');
      this.files = data.files;

      var response = JSON.parse(data.xhr.response);

      if (response.data.type === 'userAvatar') {
        this.service.avatar = response.data.imagePath;
      }

      // // console.log(this.$refs.fileInput.change());

      // for (var i = 0, f; f = this.files[i]; i++) {
      //   event.files = this.files[i]
      //   this.images.push({
      //     url: URL.createObjectURL(this.files[i]),
      //     name: this.files[i].name
      //   })
      // }

      // this.$toast.add({severity: 'info', summary: 'Успешно', detail: 'Файл загружен', life: 3000, group: 'bl',});
    },
    async onDeleteFile(i, service) {
      this.images.splice(i, 1);
    },
  },
  computed: {
    fieldStr() {
      if (typeof this.field === 'object') {
        return this.field.join('.');
      }

      return this.field;
    },
    serviceF: {
      get() {
        if (typeof this.field === 'object') {
          let serviceF = this.service;

          for (let f of this.field) {
            if (serviceF[f] === undefined) {
              return null;
            }
            serviceF = serviceF[f];
          }

          return serviceF;
        }

        return this.service[this.field];
      },
      set(v) {
        if (typeof this.field === 'object') {
          // Перебором присвоение не сработало, поэтому сравниваем по кол-ву элементов
          if (this.service[this.field[0]] === undefined) {
            this.service[this.field[0]] = {};
          }
          if (this.field.length === 2) {
            this.service[this.field[0]][this.field[1]] = v;
          } else if (this.field.length === 3) {
            if (this.service[this.field[0]][this.field[1]] === undefined) {
              this.service[this.field[0]][this.field[1]] = {};
            }
            this.service[this.field[0]][this.field[1]][this.field[2]] = v;
          }
        } else {
          this.service[this.field] = v;
        }
      },
    },
  },
};
</script>

<style scoped>
.helpText {
  color: #808080;
  font-size: 12px;
}
</style>
